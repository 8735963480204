.full-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout {
  min-height: 100vh;

  .ant-layout.header {
    background: #fff;
  }

  .header-logo {
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    float: left;
  }
  .header-menu {
    line-height: 64px;
  }
}
